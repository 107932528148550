export const saveLocalStorage = (key: string, value: string) => {
  if (!localStorage.getItem(key) || localStorage.getItem(key) !== value)
    localStorage.setItem(key, value);
};

export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
  return null;
};

export const existsAtLocalStorage = (key: string) => localStorage.getItem(key);

export const existsUrlParam = (key: string) =>
  new URLSearchParams(window.location.search).get(key);
