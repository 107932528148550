import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

import Button from "./overrides/Button";
import Link from "./overrides/Link";
import Input from "./overrides/Input";
import FormLabel from "./overrides/FormLabel";

const breakpoints = createBreakpoints({
  xs: "800px",
  sm: "1081px",
  md: "1281px",
  lg: "1367px",
  xl: "1537px",
});

const theme = extendTheme({
  colors: {
    primary: {
      main: "#E61E28",
    },
    black: {
      100: "#242429",
      200: "#243533",
      300: "#8F8F93",
      400: "#8F9796",
      500: "#AEB2B5",
      600: "#AAB4B5",
      700: "#C2C2C7",
      800: "#D9D9D9",
      900: "#E2E2E5",
    },
    white: {
      200: "#F5F6F8",
      300: "#F3F3F3",
      400: "#F8F8F8",
      500: "#FFFFFF",
    },
  },
  fonts: {
    heading: "Arial",
    body: "Roboto",
  },
  fontSizes: {
    md: "0.875rem", // 14px @ 16px
    sm: "0.6875rem", //11px @ 16px
  },
  components: {
    Button,
    Link,
    Input,
    FormLabel,
  },
  styles: {
    global: () => ({
      body: {
        fontFamily: "body",
        lineHeight: "base",
        bg: "white.400",
      },
    }),
  },
  breakpoints,
});

export default theme;
