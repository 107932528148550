import { ChakraProvider } from "@chakra-ui/react";
import { css, Global } from "@emotion/react";
import { Route, Router, Switch } from "react-router";
import { createBrowserHistory } from "history";

import "./reset.css";
import "@fontsource/roboto";

import theme from "./config/theme/theme";
import Frame from "./core/Frame";
import Container from "./core/Container";
import LoginForm from "./components/LoginForm";
import SignUpForm from "./components/SignUpForm";
import ConfirmSignUp from "./components/ConfirmSignUp";
import ForgotPasswordForm from "./components/ForgotPassword";
import RecoverPassword from "./components/RecoverPasssword";
import ForceChangePassword from "./components/ForceChangePassword";
import { SharedDataProvider } from "./context/SharedData";

const GlobalStyles = css`
  /*
  This will hide the focus indicator if the element receives focus    via the mouse,
  but it will still show up on keyboard focus.
*/
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

export const browserHistory = createBrowserHistory();

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Global styles={GlobalStyles} />
      <SharedDataProvider>
        <Frame>
          <Container>
            <Router history={browserHistory}>
              <Switch>
                <Route
                  path="/forced-change-password"
                  component={() => <ForceChangePassword />}
                />
                <Route
                  path="/recover-password"
                  component={() => <RecoverPassword />}
                />
                <Route
                  path="/forgot-password"
                  component={() => <ForgotPasswordForm />}
                />
                <Route path="/confirm" component={() => <ConfirmSignUp />} />
                <Route path="/signup" component={() => <SignUpForm />} />
                <Route path="/" component={() => <LoginForm />} />
              </Switch>
            </Router>
          </Container>
        </Frame>
      </SharedDataProvider>
    </ChakraProvider>
  );
};

export default App;
