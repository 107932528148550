import React from "react";

export interface ISharedDataContext {
  user: any;
}

type AvailableActionsType = "ADD_USER";

interface IAction {
  type: AvailableActionsType;
  payload: any;
}

export const SharedDataContext = React.createContext<
  | {
      state: ISharedDataContext;
      dispatch: React.Dispatch<IAction>;
    }
  | undefined
>(undefined);

const sharedDataReducer = (state: ISharedDataContext, action: IAction) => {
  switch (action.type) {
    case "ADD_USER": {
      return { ...state, user: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const SharedDataProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(sharedDataReducer, { user: null });

  const value = { state, dispatch };

  return (
    <SharedDataContext.Provider value={value}>
      {children}
    </SharedDataContext.Provider>
  );
};

export const useSharedData = () => {
  const context = React.useContext(SharedDataContext);

  if (context === undefined) {
    throw new Error("useSharedData must be used within a SharedDataProvider");
  }
  return context as {
    state: ISharedDataContext;
    dispatch: React.Dispatch<IAction>;
  };
};
