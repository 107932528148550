import { Flex } from "@chakra-ui/layout";
import React from "react";

interface IProps {}

const Frame: React.FC<IProps> = (props) => {
  return (
    <Flex
      w="100%"
      h="100%"
      alignItems="center"
      justifyContent="center"
      bg="white.200"
    >
      {props.children}
    </Flex>
  );
};

export default Frame;
