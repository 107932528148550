import { Box, Link, Text } from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  useToast,
  Tooltip,
  Collapse,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormHelperText,
  HStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { Auth } from "aws-amplify";

import { navigateWithQueryParams } from "./utils";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import PasswordInput from "./PasswordInput";

interface IRecoverPasswordFormValues {
  email: string;
  recoverPasswordCode: string;
  password: string;
}

const initialRecoverPasswordvalues: IRecoverPasswordFormValues = {
  email: "",
  recoverPasswordCode: "",
  password: "",
};

const RecoverPasswordSchema = Yup.object().shape({
  recoverPasswordCode: Yup.number()
    .required("Recovery code is required")
    .min(6, "Recovery 1 code is 6 characters long"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .strict()
    .matches(/[0-9]{1,}/gm, "Must contain at least 1 digit")
    .matches(/[a-z]{1,}/gm, "Must contain at least 1 lower case letter")
    .matches(/[A-Z]{1,}/gm, "Must contain at least 1 upper case letter")
    .min(8, "Length must be at least 8 characters")
    .required("Password is required"),
});

interface IProps {}

const RecoverPassword: React.FC<IProps> = (props) => {
  const [showAlert, setShowAlert] = useState<
    boolean | { title: string; description: string }
  >(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const toast = useToast();

  const onSubmit = async (values: IRecoverPasswordFormValues, actions: any) => {
    const { email, recoverPasswordCode: confirmationCode, password } = values;
    setIsLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, confirmationCode, password);
      navigateWithQueryParams("/", history);
      toast({
        title: "Password reset successful",
        status: "success",
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
      toast({
        title: (err as Error).message,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resendRecoveryCode = async (form: any) => {
    const { email } = form.values;
    if (!email) {
      form.setTouched({ email: true });
      return;
    }
    try {
      await Auth.resendSignUp(email);
      setShowAlert({
        title: "Recovery code resend",
        description:
          "Email can take to arrive a couple of minutes. Please, don't forget to check your spam folder.",
      });
    } catch (err) {
      console.log(err);
      toast({
        title: (err as Error).message,
        status: "error",
        isClosable: true,
      });
    }
  };
  return (
    <Box>
      <Collapse in={!!showAlert} unmountOnExit animateOpacity>
        <Alert
          status="info"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          w="100%"
          mb="2rem"
        >
          <AlertIcon />
          <AlertTitle>{(showAlert as any).title!}</AlertTitle>
          <AlertDescription>{(showAlert as any).description!}</AlertDescription>
        </Alert>
      </Collapse>
      <Formik
        initialValues={initialRecoverPasswordvalues}
        onSubmit={onSubmit}
        validationSchema={RecoverPasswordSchema}
      >
        {() => (
          <Form>
            <Field name="email">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                >
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    {...field}
                    id="email"
                    placeholder="example@address.com"
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="recoverPasswordCode">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={
                    form.errors.recoverPasswordCode &&
                    form.touched.recoverPasswordCode
                  }
                  mt="1rem"
                >
                  <FormLabel htmlFor="recoverPasswordCode">
                    Recovery code
                  </FormLabel>
                  <Input
                    {...field}
                    id="recoverPasswordCode"
                    placeholder="Check your email"
                  />
                  <FormHelperText w="100%" textAlign="right">
                    <Link
                      onClick={() => {
                        resendRecoveryCode(form);
                      }}
                    >
                      Re-send recovery code
                    </Link>
                  </FormHelperText>
                  <FormErrorMessage>
                    {form.errors.recoverPasswordCode}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="password">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.password && form.touched.password}
                  mt="1rem"
                >
                  <FormLabel htmlFor="password">
                    Password&nbsp;
                    <Tooltip
                      label="For your security, the password must contain at least 1 upper case letter, 1 lower case letter, 1 digit and be as long as possible (we set a minimum of 8 characters long)"
                      fontSize="md"
                    >
                      <QuestionOutlineIcon verticalAlign="text-top" />
                    </Tooltip>
                  </FormLabel>
                  <PasswordInput
                    id="password"
                    placeholder="Enter password"
                    field={field}
                  />
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <HStack w="100%" justifyContent="space-between" mt="2rem">
              <Text>
                Already submitted new password?&nbsp;
                <Link onClick={(_) => navigateWithQueryParams("/", history)}>
                  Log in
                </Link>
              </Text>
              <Button
                variant="solidPrimary"
                mt="2rem"
                type="submit"
                isLoading={isLoading}
                loadingText="Verifying new credentials"
              >
                Confirm new password
              </Button>
            </HStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default RecoverPassword;
