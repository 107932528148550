import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {},
  variants: {
    outline: {
      field: {
        borderColor: "black.400",
        _focus: {
          borderColor: "black.200",
          boxShadow: "none",
        },
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "outline",
  },
};

export default componentOverride;
