import { Box, Link, Text } from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Tooltip,
  useToast,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PasswordInput from "./PasswordInput";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router";
import { Auth } from "aws-amplify";

import { navigateWithQueryParams } from "./utils";

interface ISignUpFormValues {
  email: string;
  fullName: string;
  password: string;
}

const initialSignUpFormvalues: ISignUpFormValues = {
  email: "",
  fullName: "",
  password: "",
};

const SignUpSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  fullName: Yup.string().required("Full name is required"),
  password: Yup.string()
    .strict()
    .matches(/[0-9]{1,}/gm, "Must contain at least 1 digit")
    .matches(/[a-z]{1,}/gm, "Must contain at least 1 lower case letter")
    .matches(/[A-Z]{1,}/gm, "Must contain at least 1 upper case letter")
    .min(8, "Length must be at least 8 characters")
    .required("Password is required"),
});

interface IProps {}

const SignUpForm: React.FC<IProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const toast = useToast();

  const onSubmit = async (values: ISignUpFormValues, actions: any) => {
    const { fullName, email, password } = values;
    setIsLoading(true);
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          "custom:fullName": fullName,
        },
      });
      toast({
        title: "Confirmation code sent",
        description:
          "It can take a couple of minutes to arrive to your email. Please remember to check your spam folder.",
        status: "info",
        isClosable: true,
      });
      navigateWithQueryParams("/confirm", history);
    } catch (err) {
      console.log(err);
      toast({
        title: (err as Error).message,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={initialSignUpFormvalues}
        onSubmit={onSubmit}
        validationSchema={SignUpSchema}
      >
        {() => (
          <Form>
            <Field name="fullName">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.fullName && form.touched.fullName}
                >
                  <FormLabel htmlFor="fullName">Full name</FormLabel>
                  <Input
                    {...field}
                    id="fullName"
                    placeholder="John Doe Ramirez"
                  />
                  <FormErrorMessage>{form.errors.fullName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="email">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                  mt="1rem"
                >
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    {...field}
                    id="email"
                    placeholder="example@address.com"
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="password">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.password && form.touched.password}
                  mt="1rem"
                >
                  <FormLabel htmlFor="password">
                    Password&nbsp;
                    <Tooltip
                      label="For your security, the password must contain at least 1 upper case letter, 1 lower case letter, 1 digit and be as long as possible (we set a minimum of 8 characters long)"
                      fontSize="md"
                    >
                      <QuestionOutlineIcon verticalAlign="text-top" />
                    </Tooltip>
                  </FormLabel>
                  <PasswordInput
                    id="password"
                    placeholder="Enter password"
                    field={field}
                  />
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <SimpleGrid
              w="100%"
              mt="2rem"
              columns={{ base: 1, xs: 2 }}
              alignItems="center"
            >
              <Text
                fontSize={{ base: "sm", md: "md" }}
                justifySelf={{ base: "center", xs: "flex-start" }}
              >
                Already have an account?&nbsp;
                <Link onClick={(_) => navigateWithQueryParams("/", history)}>
                  Log in
                </Link>
              </Text>
              <Button
                justifySelf={{ base: "center", xs: "flex-end" }}
                variant="solidPrimary"
                mt={{ base: "1rem", xs: "0rem" }}
                type="submit"
                isLoading={isLoading}
                loadingText="Creating user"
              >
                Sign up
              </Button>
            </SimpleGrid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SignUpForm;
