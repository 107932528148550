import React from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Input, InputGroup } from "@chakra-ui/input";
import { IconButton, InputRightElement } from "@chakra-ui/react";

interface IProps {
  id: string;
  placeholder?: string;
  field?: any;
}

const PasswordInput: React.FC<IProps> = (props) => {
  const { id, placeholder, field } = props;

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        type={show ? "text" : "password"}
        id={id}
        placeholder={placeholder || ""}
        {...field}
      />
      <InputRightElement width="4.5rem">
        <IconButton
          icon={!show ? <ViewIcon /> : <ViewOffIcon />}
          h="1.75rem"
          size="sm"
          onClick={handleClick}
          aria-label="View password"
        />
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInput;
