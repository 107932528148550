import { Box, Link, Text } from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Button,
  useToast,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { Auth } from "aws-amplify";

import PasswordInput from "./PasswordInput";
import { navigateWithQueryParams } from "./utils";
import { useSharedData } from "../context/SharedData";

interface ILoginFormValues {
  email: string;
  password: string;
}

const initialLoginFormvalues: ILoginFormValues = {
  email: "",
  password: "",
};

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Write your password"),
});

interface IProps {}

const LoginForm: React.FC<IProps> = (props) => {
  const { dispatch } = useSharedData();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const toast = useToast();

  const onSubmit = async (values: ILoginFormValues, actions: any) => {
    const { email, password } = values;
    setIsLoading(true);
    try {
      await Auth.signIn(email, password).then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          dispatch({
            type: "ADD_USER",
            payload: user,
          });
          navigateWithQueryParams("/forced-change-password", history);
        }
      });
    } catch (err) {
      console.log(err);
      toast({
        title: (err as Error).message,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{ ...initialLoginFormvalues }}
        onSubmit={onSubmit}
        validationSchema={LoginSchema}
      >
        {() => (
          <Form>
            <Field name="email">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                >
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    {...field}
                    id="email"
                    placeholder="example@address.com"
                    key="email"
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="password">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.password && form.touched.password}
                  mt="1rem"
                >
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <PasswordInput
                    id="password"
                    placeholder="Enter password"
                    field={field}
                  />
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  <FormHelperText w="100%" textAlign="right">
                    <Link
                      onClick={(_) =>
                        navigateWithQueryParams("/forgot-password", history)
                      }
                    >
                      Forgot your password?
                    </Link>
                  </FormHelperText>
                </FormControl>
              )}
            </Field>
            <SimpleGrid
              w="100%"
              mt={{ base: "1rem", xs: "2rem" }}
              columns={{ base: 1, xs: 2 }}
              alignItems="center"
            >
              <Text
                fontSize={{ base: "sm", md: "md" }}
                justifySelf={{ base: "center", xs: "flex-start" }}
              >
                Need an account?&nbsp;
                <Link
                  onClick={(_) => navigateWithQueryParams("/signup", history)}
                >
                  Sign up
                </Link>
              </Text>
              <Button
                justifySelf={{ base: "center", xs: "flex-end" }}
                mt={{ base: "1rem", xs: "0rem" }}
                variant="solidPrimary"
                type="submit"
                isLoading={isLoading}
                loadingText="Verifying credentials"
              >
                Log in
              </Button>
            </SimpleGrid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default LoginForm;
