import { Box, Link } from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  useToast,
  SimpleGrid,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { Auth } from "aws-amplify";

import { navigateWithQueryParams } from "./utils";

interface IForgotPasswordFormValues {
  email: string;
}

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

interface IProps {}

const ForgotPasswordForm: React.FC<IProps> = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const onSubmit = async (values: IForgotPasswordFormValues, actions: any) => {
    const { email } = values;
    setIsLoading(true);
    try {
      await Auth.forgotPassword(email);
      navigateWithQueryParams("/recover-password", history);
      toast({
        title: "Recovery code sent",
        description:
          "It can take up to a couple of minutes to receive your email. Please remember to check your spam folder.",
        status: "info",
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
      toast({
        title: (err as Error).message,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={onSubmit}
        validationSchema={ForgotPasswordSchema}
      >
        {() => (
          <Form>
            <Field name="email">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                >
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    {...field}
                    id="email"
                    placeholder="example@address.com"
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <SimpleGrid w="100%" mt="2rem" columns={2} alignItems="center">
              <Link onClick={(_) => navigateWithQueryParams("/", history)}>
                <ChevronLeftIcon verticalAlign="-15%" />
                Back
              </Link>

              <Button
                justifySelf="flex-end"
                isFullWidth
                variant="solidPrimary"
                type="submit"
                isLoading={isLoading}
                loadingText="Verifying and sending email"
              >
                Send recovery email
              </Button>
            </SimpleGrid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ForgotPasswordForm;
