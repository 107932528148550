export const defaultConfig = {
  region: "eu-west-1",
  userPoolId: "eu-west-1_9ycAc3mcT",
  userPoolWebClientId: "7kgivu38oln5mbbn77suhqdss",
  mandatorySignIn: true,
  oauth: {
    domain: "login-microservice.auth.eu-west-1.amazoncognito.com",
    scope: ["email", "profile", "openid"],
    redirectSignIn: window.location.origin + "/",
    redirectSignOut: window.location.origin + "/",
    responseType: "code",
  },
};

export const dynamicConfig = (
  poolId: string,
  clientId: string,
  domain: string,
) => ({
  region: "eu-west-1",
  userPoolId: poolId,
  userPoolWebClientId: clientId,
  mandatorySignIn: true,
  oauth: {
    domain: `${domain}.auth.eu-west-1.amazoncognito.com`,
    scope: ["email", "profile", "openid"],
    redirectSignIn: window.location.origin + "/",
    redirectSignOut: window.location.origin + "/",
    responseType: "code",
  },
});
