import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
  baseStyle: {
    color: "black.300",
    _hover: {},
  },
  sizes: {},
  variants: {},
  defaultProps: {
    size: "md",
  },
};

export default componentOverride;
