import { Box, Link, Text } from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  useToast,
  FormHelperText,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Collapse,
  HStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { Auth } from "aws-amplify";

import { navigateWithQueryParams } from "./utils";

interface IConfirmationFormValues {
  email: string;
  confirmationCode: string;
}

const initialConfirmationCodevalues: IConfirmationFormValues = {
  email: "",
  confirmationCode: "",
};

const ConfirmSignUpSchema = Yup.object().shape({
  confirmationCode: Yup.number()
    .required("Confirmation code is required")
    .min(6, "Verification code is 6 characters long"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

interface IProps {}

const ConfirmSignUp: React.FC<IProps> = (props) => {
  const [showAlert, setShowAlert] = useState<
    boolean | { title: string; description: string }
  >(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const toast = useToast();

  const onSubmit = async (values: IConfirmationFormValues, actions: any) => {
    const { email, confirmationCode } = values;
    setIsLoading(true);
    try {
      await Auth.confirmSignUp(email, confirmationCode);
      toast({
        title: "Account confirmation successful",
        status: "success",
        isClosable: true,
      });
      navigateWithQueryParams("/", history);
    } catch (err) {
      console.log(err);
      toast({
        title: (err as Error).message,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resendVerificationCode = async (form: any) => {
    const { email } = form.values;
    if (!email) {
      form.setTouched({ email: true });
      return;
    }
    try {
      await Auth.resendSignUp(email);
      setShowAlert({
        title: "Confirmation code sent",
        description:
          "Email can take to arrive a couple of minutes. Please, don't forget to check your spam folder.",
      });
    } catch (err) {
      console.log(err);
      toast({
        title: (err as Error).message,
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Collapse in={!!showAlert} unmountOnExit animateOpacity>
        <Alert
          status="info"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          w="100%"
          mb="2rem"
        >
          <AlertIcon />
          <AlertTitle>{(showAlert as any).title!}</AlertTitle>
          <AlertDescription>{(showAlert as any).description!}</AlertDescription>
        </Alert>
      </Collapse>
      <Formik
        initialValues={initialConfirmationCodevalues}
        onSubmit={onSubmit}
        validationSchema={ConfirmSignUpSchema}
      >
        {() => (
          <Form>
            <Field name="email">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                >
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    {...field}
                    id="email"
                    placeholder="example@address.com"
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="confirmationCode">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={
                    form.errors.confirmationCode &&
                    form.touched.confirmationCode
                  }
                  mt="1rem"
                >
                  <FormLabel htmlFor="confirmationCode">
                    Confirmation code
                  </FormLabel>
                  <Input
                    {...field}
                    id="confirmationCode"
                    placeholder="Check your email"
                  />
                  <FormHelperText w="100%" textAlign="right">
                    <Link
                      onClick={() => {
                        resendVerificationCode(form);
                      }}
                    >
                      Re-send confirmation code
                    </Link>
                  </FormHelperText>
                  <FormErrorMessage>
                    {form.errors.confirmationCode}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <HStack w="100%" justifyContent="space-between" mt="2rem">
              <Text>
                Already confirmed?&nbsp;
                <Link onClick={(_) => navigateWithQueryParams("/", history)}>
                  Log in
                </Link>
              </Text>
              <Button
                variant="solidPrimary"
                mt="2rem"
                type="submit"
                isLoading={isLoading}
                loadingText="Verifying account"
              >
                Confirm account
              </Button>
            </HStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ConfirmSignUp;
