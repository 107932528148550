import { Image } from "@chakra-ui/image";
import { Box, HStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import Amplify, { Auth, Hub } from "aws-amplify";
import React from "react";

import logoArup from "./../assets/images/arup_red.svg";

import { defaultConfig, dynamicConfig } from "./../config/amplify.config";
import {
  existsAtLocalStorage,
  existsUrlParam,
  removeFromLocalStorage,
  saveLocalStorage,
} from "./utils";

interface IProps {}

const Container: React.FC<IProps> = (props) => {
  const [token, setToken] = React.useState<any>(null);

  function getToken() {
    return Auth.currentSession()
      .then((session) => session)
      .catch((err) => console.log(err));
  }

  const onClick = () => {
    Auth.federatedSignIn({ customProvider: "AzureAD" });
  };

  const redirectToApp = () => {
    const redirectTo = localStorage.getItem("nextUrl");
    window.location.replace(
      `${redirectTo}/?idToken=${token.idToken.jwtToken}&refreshToken=${token.refreshToken.token}&next=${redirectTo}`,
    );
  };

  Hub.listen("auth", ({ payload: { event, data } }) => {
    switch (event) {
      case "signIn":
      case "cognitoHostedUI":
        getToken().then((userToken) => setToken(userToken));
        break;
      case "signOut":
        setToken(null);
        break;
      case "signIn_failure":
      case "cognitoHostedUI_failure":
        console.log("Sign in failure", data);
        break;
    }
  });

  React.useEffect(() => {
    let poolId = existsUrlParam("poolid") || existsAtLocalStorage("poolId");
    let clientId =
      existsUrlParam("clientid") || existsAtLocalStorage("clientId");
    let app = existsUrlParam("app") || existsAtLocalStorage("app");
    const nextUrl =
      existsUrlParam("nextUrl") || existsAtLocalStorage("nextUrl");

    // If nextUrl but no other params, means app will login with default pool
    // which is login-microservice.
    //
    // We store in localStorage as we want to know, after comming from
    // federatedSignIn, where to go next with the corresponding token.
    if (
      existsUrlParam("nextUrl") &&
      !existsUrlParam("poolId") &&
      !existsUrlParam("clientId") &&
      !existsUrlParam("app")
    ) {
      poolId = removeFromLocalStorage("poolId");
      clientId = removeFromLocalStorage("clientId");
      app = removeFromLocalStorage("app");
    } else if (nextUrl && poolId && clientId && app) {
      saveLocalStorage("poolId", poolId);
      saveLocalStorage("clientId", clientId);
      saveLocalStorage("app", app);
      saveLocalStorage("nextUrl", nextUrl);
    }

    if (!poolId || !clientId || !app)
      Amplify.configure({
        Auth: defaultConfig,
      });
    else {
      Amplify.configure({
        Auth: dynamicConfig(poolId, clientId, app),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (token) {
      redirectToApp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Box
      transition="all 0.3s"
      boxShadow="2xl"
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%,-50%)"
      bg="white.500"
      minWidth={{ base: "80%", xs: "40%", md: "25%" }}
      height="fit-content"
      borderRadius="md"
      padding="1.75rem"
    >
      <HStack w="100%" justifyContent="center" mb="2rem">
        <Image src={logoArup} alt="Logo arup" w="7.5rem" />
      </HStack>
      <HStack
        w="100%"
        justifyContent={{ base: "center", sm: "flex-end" }}
        mb="0.75rem"
      >
        <Button onClick={onClick} variant="outlinePrimary">
          Arup Login
        </Button>
      </HStack>

      {props.children}
    </Box>
  );
};

export default Container;
