import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { useSharedData } from "../context/SharedData";
import PasswordInput from "./PasswordInput";
import { navigateWithQueryParams } from "./utils";

interface IFormValues {
  password: string;
}

const initialFormValues: IFormValues = {
  password: "",
};

const FormSchema = Yup.object().shape({
  password: Yup.string()
    .strict()
    .matches(/[0-9]{1,}/gm, "Must contain at least 1 digit")
    .matches(/[a-z]{1,}/gm, "Must contain at least 1 lower case letter")
    .matches(/[A-Z]{1,}/gm, "Must contain at least 1 upper case letter")
    .min(8, "Length must be at least 8 characters")
    .required("Password is required"),
});

interface IProps {}

const ForceChangePassword: React.FC<IProps> = (props) => {
  const history = useHistory();
  const toast = useToast();
  const { state } = useSharedData();
  const [isLoading, setIsLoading] = React.useState(false);

  if (!state.user) {
    navigateWithQueryParams("/", history);
    return <></>;
  }

  const onSubmit = async (values: IFormValues, actions: any) => {
    const { password } = values;
    setIsLoading(true);
    try {
      await Auth.completeNewPassword(state.user, password);
      toast({
        title: "Password update successful",
        description: "You may login now",
        status: "success",
        isClosable: true,
      });
      navigateWithQueryParams("/", history);
    } catch (err) {
      console.log(err);
      toast({
        title: (err as Error).message,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      validationSchema={FormSchema}
    >
      {() => (
        <Form>
          <FormControl mt="1rem">
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              value={state.user.challengeParam.userAttributes.email}
              isDisabled
            />
          </FormControl>
          <Field name="password">
            {({ field, form }: any) => (
              <FormControl
                isInvalid={form.errors.password && form.touched.password}
                mt="1rem"
              >
                <FormLabel htmlFor="password">
                  Password&nbsp;
                  <Tooltip
                    label="For security reasons, passwords must contain at least 1 upper case letter, 1 lower case letter, 1 digit and be at least 8 characters long."
                    fontSize="md"
                  >
                    <QuestionOutlineIcon verticalAlign="text-top" />
                  </Tooltip>
                </FormLabel>
                <PasswordInput
                  id="password"
                  placeholder="Enter password"
                  field={field}
                />
                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Button
            isFullWidth
            variant="solidPrimary"
            mt="2rem"
            type="submit"
            isLoading={isLoading}
            loadingText="Creating user"
          >
            Change password
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ForceChangePassword;
